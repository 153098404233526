<template>
    <div class="filter-panel">
        <div style="align-items: center;display: flex;margin-bottom: 15px">
            <div class="title" style="padding-top: 0px">绑定园区网站</div>
            <input
                    type="text"
                    v-model="search"
                    class="cs-input"
                    placeholder="请输入"
                    style="margin-bottom: 0"
            />

            <button @click="onSeaveBtn()" class="btn btn-primary"
                    style="margin: 0;padding-bottom: 0;padding-top: 0;height: 30px">保存
            </button>
        </div>
        <div style="height: 1px;background-color: #f0f0f0;margin-bottom: 15px"></div>
        <div class="config-view">
            <div style="
                                margin: 0;
                                padding: 0;
                                border: none;
                                box-shadow: none;
                                align-items: center;
                                display: flex;">
                <div class="title" style="
                                display: inline-block;
                                vertical-align: 5px;
                                margin-right: 20px;">
                    招商电子楼书
                </div>
                <input
                        type="text"
                        v-model="buildBookUri"
                        class="cs-input"
                        placeholder="请输入"
                        style="margin-bottom: 0"
                />
                <button @click="setBuildingBookUri()" class="btn btn-primary"
                        style="margin: 0;padding-bottom: 0;padding-top: 0;height: 30px">保存
                </button>
            </div>
        </div>
        <hr>
        <div class="config-view">
            <div style="
                                margin: 0;
                                padding: 0;
                                border: none;
                                box-shadow: none;
                                align-items: center;
                                display: flex;">
                <div class="title" style="
                                margin-right: 20px;">
                    身份信息效验
                </div>
                <CSRadio
                        v-model="identityConfig"
                        :items="{ true: '开启', false: '关闭' }"
                />
                <button @click="setIdentityConfig()" class="btn btn-primary"
                        style="margin: 0 0 0 30px;padding-bottom: 0;padding-top: 0;height: 30px;">保存
                </button>
            </div>
            <div style="margin-top: 10px;margin-left: 120px">
                <svg
                        class="icon"
                        aria-hidden="true"
                        style="font-size: 16px; margin-right: 8px"
                >
                    <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                <span style="font-size: 14px;color: #999999;">开启后，用户需要填写身份证号，系统将会自动校验身份信息是否匹配。</span>
            </div>
        </div>
        <hr>
        <div class="config-view">
            <div style="
                                margin: 0;
                                padding: 0;
                                border: none;
                                box-shadow: none;
                                align-items: center;
                                display: flex;">
                <div class="title" style="
                                margin-right: 20px;">
                    大数据页面管理
                </div>
                <template>
                    <el-checkbox-group v-model="checkItem">
                        <el-checkbox style="color:black" v-for="item in checkList" :label="item" :key="item">{{item}}</el-checkbox>
                    </el-checkbox-group>
                </template>
                <button @click="setBigDataConfig()" class="btn btn-primary"
                        style="margin: 0 0 0 30px;padding-bottom: 0;padding-top: 0;height: 30px;">保存
                </button>
            </div>
            <div style="margin-top: -4px;margin-left: 120px">
                <svg
                        class="icon"
                        aria-hidden="true"
                        style="font-size: 16px; margin-right: 8px"
                >
                    <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                <span style="font-size: 14px;color: #999999;">勾选显示页面，取消勾选隐藏页面。</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        queryRegionListUrl,
        addWebSiteBindingUrl,
        queryWebSiteBindingUrl,
        editWebSiteBindingUrl,
        queryBuildingBookWebsiteUrl,
        editBuildingBookWebsiteUrl,
        queryIdentityConfigUrl,
        editIdentityConfigUrl, getBigDataConfigUrl, editBigDataConfigUrl,
    } from "@/requestUrl";
    import CSRadio from "@/components/common/CSRadio";

    export default {
        props: {
            regionCode: {
                type: String,
            },
        },
        data() {
            return {
                search: "",
                type: "",
                codeList: [],
                buildBookUri: "",
                identityConfig: false,
                checkList: ['园区综合信息大数据', '飞控巡航', '陆地巡视','智慧园区','能耗大数据看板','用户运营'],
                checkItem: []
            };
        },
        components: {
            CSRadio,
        },
        created() {
            this.querpYuanQu();
            // this.getBuildBookUri();
            this.$vc.on('setup', (item) => {
                this.selectCode(item);
                this.getBuildBookUri(item);
                this.getIdentityConfig(item);
                this.getBigDataConfig(item);
            })
        },
        methods: {
            //查询园区对应的绑定
            async selectCode(item) {

                let res = await this.$fly.get(`${queryWebSiteBindingUrl}/${item}`);
                if (res.code != 0) {
                    return;
                }

                this.search = res.data?.webSiteName;
            },
            async onSeaveBtn() {
                const res = await this.$fly.get(`${queryWebSiteBindingUrl}/${this.regionCode}`);
                if (res.code != 0) {
                    return;
                }

                if (!res.data) {
                    this.$fly
                        .post(addWebSiteBindingUrl, {
                            websiteName: this.search, // 网站名称，只能为字符数字组合 必填
                            regionCode: `${item}`, // 园区code 必填
                        })
                        .then((res) => {
                            if (res.code != 0) {
                                return;
                            }
                            this.$vc.toast("设置成功");
                        });
                } else {
                    console.log(res);
                    this.$fly
                        .put(editWebSiteBindingUrl, {
                            id: res.data.id,
                            websiteName: this.search, // 网站名称，只能为字符数字组合 必填
                            regionCode: `${item}`, // 园区code 必填
                        })
                        .then((res) => {
                            console.log(2);
                            if (res.code != 0) {
                                return;
                            }
                            this.$vc.toast("修改成功");
                        });
                }
            },
            querpYuanQu() {
                this.$fly
                    .post(queryRegionListUrl, {
                        code: "",
                        name: "",
                        pageNo: "",
                        pageSize: "",
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.codeList = res.data.datas.map((item) => {
                            return {
                                value: item.code,
                                name: item.name,
                            };
                        });
                        //this.type = this.codeList[0].value;
                        //console.log(res);
                    });
            },
            //获取楼书设置信息
            getBuildBookUri(item) {
                this.$fly.get(queryBuildingBookWebsiteUrl, {regionCode: item}).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.buildBookUri = res.data.webSiteName;
                })
            },
            //设置电子楼书信息
            setBuildingBookUri() {
                this.$fly.post(editBuildingBookWebsiteUrl, {
                    regionCode: item,
                    websiteName: this.buildBookUri
                })
                    .then(res => {
                        if (res.code != 0) {
                            return
                        }
                        this.$vc.toast(`保存成功`);
                    })
            },
            //获取身份校验设置信息
            getIdentityConfig(item) {
                this.$fly.get(queryIdentityConfigUrl, {regionCode: item}).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.identityConfig = res.data;
                })
            },
            setIdentityConfig() {
                this.$fly.post(editIdentityConfigUrl, {
                    regionCode: `${this.regionCode}`,
                    enable: this.identityConfig
                })
                    .then(res => {
                        if (res.code != 0) {
                            return
                        }
                        this.$vc.toast(`保存成功`);
                    })
            },
            //获取身份校验设置信息
            getBigDataConfig(item) {
                this.$fly.get(getBigDataConfigUrl, {regionCode: item}).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.checkItem = res.data;
                })
            },
            setBigDataConfig() {
                console.log(this.checkItem);
                if(this.checkItem.length < 2){
                    this.$vc.message("修改失败，最少选2个。");
                    return
                }

                this.$fly.get(editBigDataConfigUrl, {
                    regionCode: `${this.regionCode}`,
                    checkItem: this.checkItem.toLocaleString()
                })
                    .then(res => {
                        if (res.code != 0) {
                            return
                        }
                        this.$vc.toast(`保存成功`);
                    })
            },
        },
    };
</script>

<style scoped>
    .title {
        height: 30px;
        line-height: 30px;
        margin-right: 20px;
        width: 100px;
        text-align: right;
    }

    .filter-panel {
        margin: 0;
        padding: 0;
        border: none;
        box-shadow: none;
    }
    div.el-checkbox-group{
        margin-top: 10px !important;
    }
</style>
<style >
    div.el-checkbox-group input[type="checkbox"]{
        width: 0px !important;
        height: 0px !important;
    }
</style>
