<template>
    <div class="menu-container">
        <div class="config-view">
            <div class="form-item" style="margin-bottom: 0;" >
                <div  style="display: flex;align-items: center">
                    <div class="left-name" style="padding-left: 5em;margin-right: 20px;padding-bottom: 1px">投屏</div>
                    <CSRadio
                            v-model="functionListL.projectionSwitch"
                            :items="{ true: '开启', false: '关闭' }"
                    />
                    <button class="btn btn-primary" @click="saveConfig">保存</button>
                </div>
            </div>
        </div>
        <div style="height: 1px;background-color: #f0f0f0"></div>
        <div class="config-view">
            <setup :regionCode='this.regionCode'></setup>
        </div>

    </div>
</template>

<script>
    import CSRadio from "@/components/common/CSRadio";
    import {editBuildingBookWebsiteUrl, projection, queryBuildingBookWebsiteUrl} from "@/requestUrl";
    import setup from "@/views/ParkInformation/setUp.vue"

    export default {
        components: {
            CSRadio,
            setup
        },
        props: {
            regionCode: {
                type: String
            }
        },
        watch: {
            regionCode(newName, oldName) {
                this.getConfig();
                // this.getBuildBookUri();
            }
        },
        data() {
            return {
                functionListL: {
                    projectionSwitch: false,
                },
                buildBookUri: ""
            };
        },
        methods: {
            //开启投影
            saveConfig() {
                this.getConfig(
                    this.$fly.get(projection.controlProjectorUrl, {
                        regionCode: this.regionCode,
                        isEnable: this.functionListL.projectionSwitch ? 1 : 0
                    }).then(res => {
                        if (res.code != 0) {
                            return
                        }
                        this.$vc.toast(`修改成功`);
                    })
                );
                this.getConfig();
            },
            //获取投影状态
            getConfig() {
                this.$fly.get(projection.queryProjectorEnbaleUrl, {regionCode: this.regionCode}).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    console.log(res);
                    this.functionListL.projectionSwitch = res.data;
                })
            },
            //获取楼书设置信息
            getBuildBookUri() {
                this.$fly.get(queryBuildingBookWebsiteUrl, {regionCode: this.regionCode}).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.buildBookUri = res.data.webSiteName;
                })
            },
            //设置电子楼书信息
            setBuildingBookUri(){
              this.$fly.post(editBuildingBookWebsiteUrl, {regionCode: this.regionCode,
                                                              websiteName:this.buildBookUri})
                      .then(res => {
                if (res.code != 0) {
                  return
                }
                this.$vc.toast(`保存成功`);
              })
            },
        },
        mounted() {
            this.getConfig();
            this.getBuildBookUri();
        }
    };
</script>

<style lang="stylus" scoped>
    .filter-panel {
        .cs-select {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    .config-view {
        padding: 15px 30px;
        background: #fff;
        color: #000;


        .form-item {
            font-size: 14px;
            margin-bottom: 10px;

            div {
                display: inline-block;

                input {
                    width: 135px;
                    height: 30px;
                    border-radius: 3px;
                    border: 1px solid #979797;
                }
            }

            .btn {
                width: 50px;
                height: 30px;
                font-size: 14px;
                border-radius: 4px;
                margin-left: 30px;
                padding: 0;
            }

            &-name {
                width: 84px;
                margin-right: 20px;
            }
        }

        .config-tip {
            color: #999;
            margin-bottom: 20px;
        }
    }

    input::-webkit-input-placeholder {
        color: #999;
    }

    input::-moz-placeholder {
        color: #999;
    }

    input::-moz-placeholder {
        color: #999;
    }

    input::-ms-input-placeholder {
        color: #999;
    }

    .left-name {
        margin-right: 15px;
    }

    .menu-container {
        margin-top: 20px
    }
</style>
